import React from "react";

const Bonding = () => {
  return (
    <div
      className="bodyDashboard d-flex"
      style={{ justifyContent: "center", paddingTop: "300px", height: "100vh" }}
    >
      Coming Soon...
    </div>
  );
};

export default Bonding;
