import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
const Dashboard = () => {
  const [data, setData] = useState();
  useEffect(() => {
    fetch("https://api.bscscan.com/api?module=gastracker&action=gasoracle&apikey=KPTDXMWBX3YSG5TMJ4EDBAFXF61QWYK1NF")
      .then((response) => {
        return response.json();
      })
      .then((datum) => {
        setData(datum.result);
      });
  }, []);
  return (
    <>
      <div className="bodyDashboard">
        <p className="text">Dashboard</p>
        <div className="innerdivSection mt-5">
          <p className="text" style={{ marginLeft: "10px" }}>
            {" "}
            Rewards in $BUSD
          </p>
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              backgroundColor: "white",
              borderRadius: "0.75rem",
              padding: "1.5rem",
            }}
          >
            <p style={{ fontSize: "1.25rem" }}>My Total Rewards</p>
            <p style={{ fontSize: "1.25rem" }}>Total Rewards Distributed</p>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              padding: "1.5rem",
            }}
          >
            <p className="valuesFirst">$0.00</p>
            <p className="valuesFirst">$0.00</p>
          </div>
        </div>
        <div className="innerdivSection mt-5">
          <Container fluid>
            <Row>
              <Col md={6} style={{ padding: 0 }}>
                {" "}
                <p className="text" style={{ marginLeft: "10px" }}>
                  {" "}
                  Statistics
                </p>
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                    padding: "1.5rem",
                  }}
                >
                  <p style={{ fontSize: "1rem" }}>Coming Soon...</p>
                </div>
              </Col>
              <Col md={6}>
                <p className="text" style={{ textAlign: "center" }}>
                  {" "}
                  Gas Tracker
                </p>

                <div
                  className="d-flex forMobile"
                  style={{
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <div className="">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        color="green"
                        style={{
                          color: "green",
                          margin: "auto",
                          //   display: "flex",
                        }}
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z"></path>
                      </svg>
                      <b className="mb-0" style={{ marginLeft: "-25px" }}>
                        {data?.SafeGasPrice ?? 45}
                      </b>
                    </div>
                    <p className="">Standard</p>
                  </div>
                  <div className="">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        color="blue"
                        style={{ color: "blue" }}
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z"></path>
                      </svg>
                      <b className="mb-0" style={{ marginLeft: "5px" }}>
                        {data?.ProposeGasPrice ?? 45}
                      </b>
                    </div>
                    <p className="">Fast</p>
                  </div>
                  <div className="">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        color="red"
                        style={{ color: "red" }}
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z"></path>
                      </svg>
                      <b className="mb-0" style={{ marginLeft: "5px" }}>
                        {data?.FastGasPrice ?? 45}
                      </b>
                    </div>
                    <p>Rapid</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="innerdivSection mt-5">
          <p className="text" style={{ marginLeft: "10px" }}>
            {" "}
            Transaction history
          </p>
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              padding: "1.5rem",
            }}
          >
            <p style={{ fontSize: "0.8rem", fontWeight: "700" }}>HASH</p>
            <p style={{ fontSize: "0.8rem", fontWeight: "700" }}>DESCRIPTION</p>
            <p style={{ fontSize: "0.8rem", fontWeight: "700" }}>STATUS</p>
          </div>
        </div>
      </div>
      ;
    </>
  );
};

export default Dashboard;
