import logo from "../images/logo.png";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import SidebarContent from "./subComponents/SidebarContent";
import RightSidebarcontent from "./subComponents/RightSidebarcontent";
const MobileNav = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="mobileNavBody">
        <button
          type="button"
          className="bgmobile"
          aria-label="open menu"
          style={{ marginLeft: "15px" }}
          variant="primary"
          onClick={handleShow}
        >
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            aria-hidden="true"
            focusable="false"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
        <Offcanvas
          show={show}
          onHide={handleClose}
          className="sidebarBodyMobile"
        >
          <Offcanvas.Header closeButton>
            <img src={logo} style={{ maxWidth: "150px" }} />{" "}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <SidebarContent />
          </Offcanvas.Body>
        </Offcanvas>
        <img
          alt="void logo"
          src={logo}
          decoding="async"
          data-nimg="intrinsic"
          srcet={logo}
          className="mywalletDivImage "
          style={{ marginLeft: "35px" }}
        />
        <button className="connect " style={{ marginLeft: "35px" }}>
          Connect
        </button>
        <button
          type="button"
          className="bgmobile"
          aria-label="open wallet"
          style={{ float: "right", marginRight: "25px" }}
          variant="primary"
          onClick={handleShow2}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 12h2v4h-2z"></path>
            <path d="M20 7V5c0-1.103-.897-2-2-2H5C3.346 3 2 4.346 2 6v12c0 2.201 1.794 3 3 3h15c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zM5 5h13v2H5a1.001 1.001 0 0 1 0-2zm15 14H5.012C4.55 18.988 4 18.805 4 18V8.815c.314.113.647.185 1 .185h15v10z"></path>
          </svg>
        </button>
        <Offcanvas
          show={show2}
          onHide={handleClose2}
          className="sidebarBodyMobile"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <RightSidebarcontent />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default MobileNav;
