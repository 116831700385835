import React from "react";
import RightSidebarcontent from "./subComponents/RightSidebarcontent";

const RightSidebar = () => {
  return (
    <div className="rightSidebarbody">
      <button className="connect mt-3">Connect</button>
      <RightSidebarcontent />
    </div>
  );
};

export default RightSidebar;
