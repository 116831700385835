import React from "react";

const Taxcalculator = () => {
  return (
    <>
      <div className="bodyDashboard">
        <p className="text">Tax Calculator</p>
        <div className="innerdivSection">
          <p className="text"> $BUSD</p>
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              backgroundColor: "white",
              borderRadius: "0.75rem",
            }}
          >
            <input
              className="TaxCalculator"
              style={{
                borderRadius: "5px",
                padding: "10px",
                color: "black",
                backgroundColor: "white",
                width: "100%",
                border: "none",
                padding: "0.8rem",
              }}
              type="number"
              placeholder="Type in your amount of BUSD from your wallet"
            />
            <br />
          </div>
        </div>
        <div className="innerdivSection mt-4">
          <p className="text"> Percentage</p>
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              backgroundColor: "white",
              borderRadius: "0.75rem",
            }}
          >
            <input
              className="TaxCalculator"
              style={{
                borderRadius: "5px",
                padding: "10px",
                color: "black",
                backgroundColor: "white",
                width: "100%",
                border: "none",
                padding: "0.8rem",
              }}
              type="number"
              placeholder="Type in your personal tax percentage"
            />
          </div>
        </div>
        <div className="innerdivSection mt-4">
          <p className="text"> Tax</p>
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              backgroundColor: "#e7d618",
              borderRadius: "0.75rem",
              padding: "10px",
            }}
          >
            <p className="mb-0" style={{ color: "white" }}>
              Your estimated monthly tax
            </p>
            <p className="valuesFirst mb-0" style={{ color: "white" }}>
              $0.00
            </p>
          </div>
          <div
            className="d-flex mt-2"
            style={{
              justifyContent: "space-between",
              backgroundColor: "#fff",
              borderRadius: "0.75rem",
              padding: "10px",
            }}
          >
            <p className="mb-0">Your estimated value after tax</p>
            <p className="valuesFirst mb-0">$0.00</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Taxcalculator;
