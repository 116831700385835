import React from "react";
import { Accordion } from "react-bootstrap";
import logo from "../../images/logo.png";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const RightSidebarcontent = () => {
  const popover = (
    <Popover
      id="popover-basic"
      style={{
        backgroundColor: "white",
      }}
    >
      <Popover.Body className="px-3">
        <div
          className="d-flex"
          style={{
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <button
            type="button"
            className="popButton d-flex"
            style={{
              justifyContent: "space-between",
              backgroundColor: "white",
              color: "black",
            }}
          >
            Copy address
            <span className="ml-4" style={{ marginLeft: "15px" }}>
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
                focusable="false"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
            </span>
          </button>
          <button
            type="button"
            className="popButton"
            style={{
              justifyContent: "space-between",
              backgroundColor: "white",
              color: "black",
            }}
          >
            Disconnect
            <span className="" style={{ marginLeft: "15px" }}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
              </svg>
            </span>
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <Accordion defaultActiveKey="0" className="mt-5">
        <Accordion.Item eventKey="0">
          <Accordion.Header>My Wallet</Accordion.Header>
          <Accordion.Body>
            <div className="walletBody">
              <div
                className="d-flex "
                style={{ justifyContent: "space-between", marginLeft: "8px" }}
              >
                <span className="mywalletMain ml-2">
                  <img
                    alt="void logo"
                    src={logo}
                    decoding="async"
                    data-nimg="intrinsic"
                    srcet={logo}
                    className="mywalletDivImage mt-2"
                  />
                </span>
                <div className="mt-2">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                  >
                    <Button className="moreIcon">
                      {" "}
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                        focusable="false"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
                      </svg>
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>

              <div
                style={{ marginLeft: "8px", color: "white" }}
                className="mt-4"
              >
                <p className="mb-0">**** **** **** ****</p>
              </div>
              <div
                style={{
                  backgroundColor: "#f5c242",
                  borderRadius: "0 0  0.5rem 0.5rem",
                }}
              >
                <p className="px-2 mt-2" style={{ color: "white" }}>
                  Balance in $WARP
                </p>
                <div
                  className="d-flex px-2"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <p className="valuesFirst " style={{ color: "white" }}>
                    $0.00
                  </p>
                  <p className="mt-2" style={{ color: "white" }}>
                    BALANCE
                  </p>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <p
          className="disabled mt-4 "
          style={{
            cursor: "not-allowed",
            color: "lightgrey",
            fontWeight: "600",
            marginLeft: "20px",
          }}
        >
          My Bonds (coming soon)
        </p>
        <p
          className="disabled mt-4 accordion-button:not(.collapsed)"
          style={{
            cursor: "not-allowed",
            color: "lightgrey",
            fontWeight: "600",
            marginLeft: "20px",
          }}
        >
          My NFT (coming soon)
        </p>
      </Accordion>
    </>
  );
};

export default RightSidebarcontent;
