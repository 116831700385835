import React from "react";

const Announcments = () => {
  const post = [
    "Attention all crypto enthusiasts! We are excited to announce that our project has officially opened a Telegram channel! Don't miss out on this opportunity to be a part of our community. See you on Telegram! https://t.me/+4xbSjIq0ZnBjNzQ0 #BSCGems #PassiveIncome #crypto #DeFi #BNB",
    "Something big is coming… #bsc #cryptocurrency #DeFi #bscgems #bnb",
    "Our code has been successfully audited by Cyberscope! You can find the Audit Report here:https://github.com/cyberscope-io/audits/blob/main/warp/audit.pdf #Audit #cyberscope #passiveincome #Crypto",
    "We are thrilled to announce that our fair launch is now live on PinkSale! Don't miss out on this chance to join us on this exciting journey. Visit PinkSale now and take part in our fair launch! https://pinksale.finance/launchpad/0x513d9c6b95569005f854e9624547Eed292601bF1?chain=BSC #Fairlaunch #PinkSale #BSCGems #crypto #BNB",
  ];
  const renderList = post.map((item, index) => (
    <div className="d-flex sectionTwitter mt-3">
      <p className="mt-3" key={index}>
        {item}
      </p>
    </div>
  ));
  return (
    <>
      <div className="bodyDashboard">
        <p className="text">Announcements</p>
        <div className="innerdivSection">
          <p className="text">Twitter</p>
          {renderList}
        </div>
      </div>
    </>
  );
};

export default Announcments;
