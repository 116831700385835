import React, { useState } from "react";
import SidebarContent from "./subComponents/SidebarContent";
const Sidebar = () => {
  return (
    <>
      <div className="sidebarBody">
        <SidebarContent />
      </div>
    </>
  );
};

export default Sidebar;
