import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import Taxcalculator from "./components/Taxcalculator";
import Announcments from "./components/Announcments";
import { Router, Route, Routes, BrowserRouter } from "react-router-dom";
import RightSidebar from "./components/RightSidebar";
import Bonding from "./components/Bonding";
import MobileNav from "./components/MobileNav";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Sidebar />
        <MobileNav />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/Bonding" element={<Bonding />} />
          <Route path="/Announcement" element={<Announcments />} />
          <Route path="/Taxcalculator" element={<Taxcalculator />} />
        </Routes>
        <RightSidebar />
      </BrowserRouter>
      {/* <Dashboard /> */}
      {/* <Taxcalculator /> */}
      {/* <Announcments /> */}
    </div>
  );
}

export default App;
